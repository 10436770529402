
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useAuth } from 'src/composables/auth';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//ekin_guru routes
		{ path: '/ekin_guru', name: 'ekin_gurulist', component: () => import('pages/ekin_guru/list.vue'), props: true },
		{ path: '/ekin_guru/index/:fieldName?/:fieldValue?', component: () => import('pages/ekin_guru/list.vue'), props: true },
		{ path: '/ekin_guru/view/:id', name: 'ekin_guruview', component: () => import('pages/ekin_guru/view.vue'), props: true },
		{ path: '/ekin_guru/add', name: 'ekin_guruadd', component: () => import('pages/ekin_guru/add.vue'), props: true },
		{ path: '/ekin_guru/edit/:id', name: 'ekin_guruedit', component: () => import('pages/ekin_guru/edit.vue'), props: true },
		{ path: '/ekin_guru/list_user_guru', name: 'ekin_gurulist_user_guru', component: () => import('pages/ekin_guru/list_user_guru.vue'), props: true },
		{ path: '/ekin_guru/edit_user_guru/:id', name: 'ekin_guruedit_user_guru', component: () => import('pages/ekin_guru/edit_user_guru.vue'), props: true },
		{ path: '/ekin_guru/list_penilaian_guru', name: 'ekin_gurulist_penilaian_guru', component: () => import('pages/ekin_guru/list_penilaian_guru.vue'), props: true },
		{ path: '/ekin_guru/edit_guru/:id', name: 'ekin_guruedit_guru', component: () => import('pages/ekin_guru/edit_guru.vue'), props: true },

//ekin_tu routes
		{ path: '/ekin_tu', name: 'ekin_tulist', component: () => import('pages/ekin_tu/list.vue'), props: true },
		{ path: '/ekin_tu/index/:fieldName?/:fieldValue?', component: () => import('pages/ekin_tu/list.vue'), props: true },
		{ path: '/ekin_tu/view/:id', name: 'ekin_tuview', component: () => import('pages/ekin_tu/view.vue'), props: true },
		{ path: '/ekin_tu/add', name: 'ekin_tuadd', component: () => import('pages/ekin_tu/add.vue'), props: true },
		{ path: '/ekin_tu/edit/:id', name: 'ekin_tuedit', component: () => import('pages/ekin_tu/edit.vue'), props: true },
		{ path: '/ekin_tu/list_user_tu', name: 'ekin_tulist_user_tu', component: () => import('pages/ekin_tu/list_user_tu.vue'), props: true },
		{ path: '/ekin_tu/list_penilaian_tu', name: 'ekin_tulist_penilaian_tu', component: () => import('pages/ekin_tu/list_penilaian_tu.vue'), props: true },
		{ path: '/ekin_tu/edit_user_tu/:id', name: 'ekin_tuedit_user_tu', component: () => import('pages/ekin_tu/edit_user_tu.vue'), props: true },
		{ path: '/ekin_tu/edit_tu/:id', name: 'ekin_tuedit_tu', component: () => import('pages/ekin_tu/edit_tu.vue'), props: true },

//permissions routes
		{ path: '/permissions', name: 'permissionslist', component: () => import('pages/permissions/list.vue'), props: true },
		{ path: '/permissions/index/:fieldName?/:fieldValue?', component: () => import('pages/permissions/list.vue'), props: true },
		{ path: '/permissions/view/:id', name: 'permissionsview', component: () => import('pages/permissions/view.vue'), props: true },
		{ path: '/permissions/add', name: 'permissionsadd', component: () => import('pages/permissions/add.vue'), props: true },
		{ path: '/permissions/edit/:id', name: 'permissionsedit', component: () => import('pages/permissions/edit.vue'), props: true },

//roles routes
		{ path: '/roles', name: 'roleslist', component: () => import('pages/roles/list.vue'), props: true },
		{ path: '/roles/index/:fieldName?/:fieldValue?', component: () => import('pages/roles/list.vue'), props: true },
		{ path: '/roles/view/:id', name: 'rolesview', component: () => import('pages/roles/view.vue'), props: true },
		{ path: '/roles/add', name: 'rolesadd', component: () => import('pages/roles/add.vue'), props: true },
		{ path: '/roles/edit/:id', name: 'rolesedit', component: () => import('pages/roles/edit.vue'), props: true },

//s_abesn routes
		{ path: '/s_abesn', name: 's_abesnlist', component: () => import('pages/s_abesn/list.vue'), props: true },
		{ path: '/s_abesn/index/:fieldName?/:fieldValue?', component: () => import('pages/s_abesn/list.vue'), props: true },
		{ path: '/s_abesn/view/:id', name: 's_abesnview', component: () => import('pages/s_abesn/view.vue'), props: true },
		{ path: '/s_abesn/add', name: 's_abesnadd', component: () => import('pages/s_abesn/add.vue'), props: true },
		{ path: '/s_abesn/edit/:id', name: 's_abesnedit', component: () => import('pages/s_abesn/edit.vue'), props: true },

//s_master_nilai routes
		{ path: '/s_master_nilai', name: 's_master_nilailist', component: () => import('pages/s_master_nilai/list.vue'), props: true },
		{ path: '/s_master_nilai/index/:fieldName?/:fieldValue?', component: () => import('pages/s_master_nilai/list.vue'), props: true },
		{ path: '/s_master_nilai/view/:id', name: 's_master_nilaiview', component: () => import('pages/s_master_nilai/view.vue'), props: true },
		{ path: '/s_master_nilai/add', name: 's_master_nilaiadd', component: () => import('pages/s_master_nilai/add.vue'), props: true },
		{ path: '/s_master_nilai/edit/:id', name: 's_master_nilaiedit', component: () => import('pages/s_master_nilai/edit.vue'), props: true },

//s_tampilan_petugas_uks routes
		{ path: '/s_tampilan_petugas_uks', name: 's_tampilan_petugas_ukslist', component: () => import('pages/s_tampilan_petugas_uks/list.vue'), props: true },
		{ path: '/s_tampilan_petugas_uks/index/:fieldName?/:fieldValue?', component: () => import('pages/s_tampilan_petugas_uks/list.vue'), props: true },
		{ path: '/s_tampilan_petugas_uks/view/:id', name: 's_tampilan_petugas_uksview', component: () => import('pages/s_tampilan_petugas_uks/view.vue'), props: true },
		{ path: '/s_tampilan_petugas_uks/add', name: 's_tampilan_petugas_uksadd', component: () => import('pages/s_tampilan_petugas_uks/add.vue'), props: true },
		{ path: '/s_tampilan_petugas_uks/edit/:id', name: 's_tampilan_petugas_uksedit', component: () => import('pages/s_tampilan_petugas_uks/edit.vue'), props: true },
		{ path: '/s_tampilan_petugas_uks/list_tampilan_petugas_uks', name: 's_tampilan_petugas_ukslist_tampilan_petugas_uks', component: () => import('pages/s_tampilan_petugas_uks/list_tampilan_petugas_uks.vue'), props: true },

//s_tampilan_print_petugas_uks routes
		{ path: '/s_tampilan_print_petugas_uks', name: 's_tampilan_print_petugas_ukslist', component: () => import('pages/s_tampilan_print_petugas_uks/list.vue'), props: true },
		{ path: '/s_tampilan_print_petugas_uks/index/:fieldName?/:fieldValue?', component: () => import('pages/s_tampilan_print_petugas_uks/list.vue'), props: true },
		{ path: '/s_tampilan_print_petugas_uks/view/:id', name: 's_tampilan_print_petugas_uksview', component: () => import('pages/s_tampilan_print_petugas_uks/view.vue'), props: true },
		{ path: '/s_tampilan_print_petugas_uks/add', name: 's_tampilan_print_petugas_uksadd', component: () => import('pages/s_tampilan_print_petugas_uks/add.vue'), props: true },
		{ path: '/s_tampilan_print_petugas_uks/edit/:id', name: 's_tampilan_print_petugas_uksedit', component: () => import('pages/s_tampilan_print_petugas_uks/edit.vue'), props: true },
		{ path: '/s_tampilan_print_petugas_uks/list_tampilan_print', name: 's_tampilan_print_petugas_ukslist_tampilan_print', component: () => import('pages/s_tampilan_print_petugas_uks/list_tampilan_print.vue'), props: true },

//user routes
		{ path: '/user', name: 'userlist', component: () => import('pages/user/list.vue'), props: true },
		{ path: '/user/index/:fieldName?/:fieldValue?', component: () => import('pages/user/list.vue'), props: true },
		{ path: '/user/view/:id', name: 'userview', component: () => import('pages/user/view.vue'), props: true },
		{ path: '/account/edit', name: 'useraccountedit', component: () => import('pages/account/accountedit.vue'), props: true },
		{ path: '/account', name: 'useraccountview', component: () => import('pages/account/accountview.vue'), props: true },
		{ path: '/user/add', name: 'useradd', component: () => import('pages/user/add.vue'), props: true },
		{ path: '/user/edit/:id', name: 'useredit', component: () => import('pages/user/edit.vue'), props: true },
		{ path: '/user/list_tampilan_foto', name: 'userlist_tampilan_foto', component: () => import('pages/user/list_tampilan_foto.vue'), props: true },
		{ path: '/user/edit_photo_user_tu/:id', name: 'useredit_photo_user_tu', component: () => import('pages/user/edit_photo_user_tu.vue'), props: true },

		
		
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	const auth = useAuth();

	
	const { user, userRole } = await auth.getUserData();
	if(user){
		const roleName = userRole.toLowerCase();
		
		let rolePage;
		switch(roleName){
			case "penilaian_guru":
				rolePage = "penilaian_guru";
				break;
			case "penilaian_tu":
				rolePage = "penilaian_tu";
				break;
			case "petugas_ekin":
				rolePage = "petugas_ekin";
				break;
			case "user_guru":
				rolePage = "user_guru";
				break;
			case "user_tu":
				rolePage = "user_tu";
				break;
			default:
				rolePage = "home";
		}
		
		const homeRoute = {
			path: '/', alias: '/home', name: 'home', component: () => import(`pages/home/${rolePage}.vue`) 
		}

		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { path: '/',  alias: '/home', name: 'index', component: () => import('pages/index/index.vue') }
		mainRoute.children.push(indexRoute);
	}

	const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

